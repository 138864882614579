import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import FolderView from '@/views/FolderView.vue';
import AboutView from '@/views/AboutView.vue';
import ContactView from '@/views/ContactView.vue';

const routes = [
  { path: '/', component: HomeView },
  { path: '/illustrations', component: FolderView, props: { folderType: 'illustrations' } },
  { path: '/sequentials', component: FolderView, props: { folderType: 'sequentials' } },
  { path: '/publications', component: FolderView, props: { folderType: 'publications' } },
  { path: '/about', component: AboutView },
  { path: '/contact', component: ContactView }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
