<template>
  <div>
    <h1>Contact</h1>
    Reach the artist at <a :href="mailToLink">mark@markeoghanjones.art</a>&nbsp;&nbsp;&nbsp;<a href="mailto:mark@markeoghanjones.art" aria-label="Email">
      <i class="fas fa-envelope"></i> <!-- Email Icon -->
    </a>
  </div>
</template>


<script>
export default {
  data() {
    return {
      email: 'contact@example.com',
      subject: 'Inquiry about your work',
      body: 'Hello, I would like to know more about...'
    };
  },
  computed: {
    mailToLink() {
      return `mailto:${this.email}?subject=${encodeURIComponent(this.subject)}&body=${encodeURIComponent(this.body)}`;
    }
  }
};
</script>

<style scoped>
* {
  color: white;
}
</style>
