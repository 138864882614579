<template>
  <div :class="`${folderType}-view`">
    <div v-if="folders.length === 0" class="under-construction">
      <slot name="empty-message">
        {{ capitalizedFolderType }} is Under Construction
        <div class="check-back-soon">Check back soon!</div>
      </slot>
    </div>
    <div v-else class="folder-list">
      <div v-for="folder in folders" :key="folder.folderName" class="folder">
        <div @click="openModal(folder)">
          <RollingSlideshowLink
            :slides="getSlidePaths(folder)"
            :imageWidth="200"
            :imageHeight="200"
            :displayNumber="folder.jsonData?.displayNumber === 'true'"
          />
        </div>
        <div class="caption">
          {{ folder.jsonData?.caption || ' ' }}
        </div>
      </div>
      <ModalSlideshow
        v-if="isModalOpen"
        :slides="currentSlides"
        :caption="currentCaption"
        @close="closeModal"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import RollingSlideshowLink from '../components/RollingSlideshowLink.vue';
import ModalSlideshow from '../components/ModalSlideshow.vue';
import { fetchFolders } from '../utils/fetchUtils';

interface Slide {
  image: string;
  text?: string;
}

interface Folder {
  folderName: string;
  jsonData: { caption?: string; slides: Slide[]; displayNumber?: string } | null;
  path: string;
}

export default defineComponent({
  name: 'FolderView',
  components: {
    RollingSlideshowLink,
    ModalSlideshow,
  },
  props: {
    folderType: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const baseImagesPath = process.env.BASE_IMAGES_PATH || '/api/images';
    const folders = ref<Folder[]>([]);
    const isModalOpen = ref(false);
    const currentSlides = ref<Slide[]>([]);
    const currentCaption = ref<string>('');

    const capitalizedFolderType = computed(() => {
      return props.folderType.charAt(0).toUpperCase() + props.folderType.slice(1);
    });

    const loadFolders = async () => {
      try {
        folders.value = []; // Clear previous folders before loading new ones
        const fetchedFolders = await fetchFolders(props.folderType);
        folders.value = fetchedFolders.map((folder: any) => ({
          folderName: folder.folderName,
          jsonData: folder.jsonData,
          path: `${baseImagesPath}/${props.folderType}/${folder.folderName}`,
        }));
      } catch (error) {
        console.error('Error loading folders:', error);
      }
    };

    const getSlidePaths = (folder: Folder) => {
      return folder.jsonData?.slides.map((slide: Slide) => `${folder.path}/${slide.image}`) || [];
    };

    const getSlidesForModal = (folder: Folder): Slide[] => {
      return (
        folder.jsonData?.slides.map((slide: Slide) => ({
          image: `${folder.path}/${slide.image}`,
          text: slide.text,
        })) || []
      );
    };

    const openModal = (folder: Folder) => {
      currentSlides.value = getSlidesForModal(folder);
      currentCaption.value = folder.jsonData?.caption || '';
      isModalOpen.value = true;
    };

    const closeModal = () => {
      isModalOpen.value = false;
      currentSlides.value = [];
      currentCaption.value = '';
    };

    onMounted(() => {
      loadFolders();
    });

    watch(() => props.folderType, loadFolders); // Reload folders when folderType prop changes

    return {
      folders,
      getSlidePaths,
      isModalOpen,
      currentSlides,
      currentCaption,
      openModal,
      closeModal,
      capitalizedFolderType,
    };
  },
});
</script>

<style scoped>
.folder-list {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* Explicitly set to 5 columns */
  gap: 20px;
  justify-content: center;
}

@media (max-width: 1200px) {
  .folder-list {
    grid-template-columns: repeat(4, 1fr); /* 4 columns for smaller screens */
  }
}

@media (max-width: 900px) {
  .folder-list {
    grid-template-columns: repeat(3, 1fr); /* 3 columns for smaller screens */
  }
}

@media (max-width: 600px) {
  .folder-list {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for smaller screens */
  }
}

@media (max-width: 400px) {
  .folder-list {
    grid-template-columns: 1fr; /* 1 column for very small screens */
  }
}

.folder {
  cursor: pointer;
  text-align: center;
}

.caption {
  margin-top: 5px;
  font-size: 14px;
  color: #fff;
}

.under-construction {
  font-size: 36px;
  font-weight: bold;
  color: #d3d3d3; /* Light gray */
  text-align: center;
}

.check-back-soon {
  font-size: 18px;
  font-weight: normal;
  color: #d3d3d3; /* Light gray */
  margin-top: 10px;
}
</style>
