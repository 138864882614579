import API_URL from '../config';

export const fetchFolders = async (path: string): Promise<string[]> => {
  const fullApiPath = `${API_URL}/folder-images/${path}`;
  console.log(`fetchutils.ts - Fetching folders from path: ${fullApiPath}`);

  try {
    const response = await fetch(fullApiPath);

    if (!response.ok) {
      console.error('Network response was not ok', response.status, response.statusText);
      throw new Error('Network response was not ok');
    }

    const data = await response.json();

    console.log('fetchutils.ts - Data fetched:', data);

    const folders = data as string[];

    console.log('fetchutils.ts - Parsed folders:', folders);

    return folders;
  } catch (error) {
    console.error('fetchutils.ts - Error fetching folders:', error);
    throw error;
  }
};
