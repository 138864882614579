import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-8ebcf016"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "image-container" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "caption" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
  }, [
    _createElementVNode("div", {
      class: "modal-content",
      onClick: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"]))
    }, [
      _createElementVNode("button", {
        class: "close-button",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
      }, "×"),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          src: _ctx.slides[_ctx.currentIndex].image,
          alt: "Slideshow Image"
        }, null, 8, _hoisted_2),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.slides[_ctx.currentIndex].text || ''), 1)
      ]),
      (_ctx.slides.length > 1)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "nav-button prev",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.prevSlide && _ctx.prevSlide(...args)))
          }, "‹"))
        : _createCommentVNode("", true),
      (_ctx.slides.length > 1)
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            class: "nav-button next",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.nextSlide && _ctx.nextSlide(...args)))
          }, "›"))
        : _createCommentVNode("", true)
    ])
  ]))
}