import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-8f0bec92"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "outer-bounding-box" }
const _hoisted_2 = { class: "slideshow-wrapper" }
const _hoisted_3 = { class: "slideshow-container" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: "arrow left",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.prevSlide && _ctx.prevSlide(...args)))
      }, "❮"),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: "slideshow-window",
          style: _normalizeStyle({
            transform: `translateX(${-_ctx.currentIndex * _ctx.slideWidth}px)`,
            transition: _ctx.isTransitioning ? 'transform 1s ease-in-out' : 'none',
          }),
          onTransitionend: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleTransitionEnd && _ctx.handleTransitionEnd(...args)))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayImages, (image, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "slide",
              style: _normalizeStyle(_ctx.getSlideStyle(index))
            }, [
              image
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: image,
                    alt: "Slideshow Image"
                  }, null, 8, _hoisted_4))
                : _createCommentVNode("", true)
            ], 4))
          }), 128))
        ], 36)
      ]),
      _createElementVNode("button", {
        class: "arrow right",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.nextSlide && _ctx.nextSlide(...args)))
      }, "❯")
    ])
  ]))
}