<template>
  <div class="slideshow-container" ref="container">
    <div
      class="slideshow-track"
      ref="slideshowTrack"
      :style="singleImage ? singleImageStyle : trackStyle"
      @transitionend="handleTransitionEnd"
    >
      <!-- Handle single image case properly -->
      <div
        v-for="(slide, index) in displaySlides"
        :key="index"
        class="slide"
      >
        <img :src="slide" :alt="'Slideshow Image ' + (index + 1)" />
        <div v-if="displayNumber && !singleImage" class="slide-number">
          {{ getDisplayNumber(index) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, onUnmounted } from 'vue';

export default defineComponent({
  name: 'RollingSlideshowLink',
  props: {
    slides: {
      type: Array as () => string[],
      required: true,
    },
    imageWidth: {
      type: Number,
      default: 200,
    },
    imageHeight: {
      type: Number,
      default: 200,
    },
    displayNumber: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const slideshowTrack = ref<HTMLElement | null>(null);
    const currentSlide = ref(1); // Start from the first slide (index 1)
    const isTransitioning = ref(false);
    let intervalId: number | null = null;

    // Check if there's only one image
    const singleImage = computed(() => props.slides.length === 1);

    // Handle cases where there's only one image
    const displaySlides = computed(() => {
      if (singleImage.value) {
        return props.slides; // Static display if there's only one image
      }
      // Clone the first and last slides to create the infinite loop effect
      return [
        props.slides[props.slides.length - 1],
        ...props.slides,
        props.slides[0],
      ];
    });

    // Style for single image (no scrolling)
    const singleImageStyle = computed(() => ({
      transform: `translateX(0)`,
      transition: 'none', // No transition for a single image
    }));

    // Style for scrolling slideshow
    const trackStyle = computed(() => ({
      transform: `translateX(${-currentSlide.value * props.imageWidth}px)`,
      transition: isTransitioning.value ? 'transform 0.5s linear' : 'none',
    }));

    const startSlideshow = () => {
      if (props.slides.length > 1) {
        intervalId = window.setInterval(() => {
          isTransitioning.value = true;
          currentSlide.value++;
        }, 1500); // Adjust speed as necessary
      }
    };

    const stopSlideshow = () => {
      if (intervalId !== null) {
        clearInterval(intervalId);
        intervalId = null;
      }
    };

    const handleTransitionEnd = () => {
      if (currentSlide.value === props.slides.length + 1) {
        // Reset to the first "real" slide
        isTransitioning.value = false;
        currentSlide.value = 1;
      } else if (currentSlide.value === 0) {
        // Jump to the last "real" slide
        isTransitioning.value = false;
        currentSlide.value = props.slides.length;
      }
    };

    const getDisplayNumber = (index: number) => {
      if (index === 0) {
        return props.slides.length;
      } else if (index === displaySlides.value.length - 1) {
        return 1;
      } else {
        return index;
      }
    };

    // Handle visibility change using the browser's visibility API
    const handleVisibilityChange = () => {
      if (document.hidden) {
        stopSlideshow();
      } else {
        // Ensure we reset transition states properly after returning to the page
        isTransitioning.value = false;
        startSlideshow();
      }
    };

    onMounted(() => {
      if (!singleImage.value) {
        startSlideshow();
      }
      // Add the visibility change event listener
      document.addEventListener('visibilitychange', handleVisibilityChange);
    });

    onUnmounted(() => {
      stopSlideshow();
      // Remove the visibility change event listener
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    });

    return {
      slideshowTrack,
      currentSlide,
      trackStyle,
      singleImageStyle,
      handleTransitionEnd,
      displaySlides,
      getDisplayNumber,
      singleImage,
    };
  },
});
</script>

<style scoped>
.slideshow-container {
  width: 200px;
  height: 200px;
  overflow: hidden;
  position: relative;
  border: 1px solid white;
  background-color: black;
}

.slideshow-track {
  display: flex;
}

.slide {
  flex-shrink: 0;
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.slide img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.slide-number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 48px;
  font-weight: bold;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
  border-radius: 5px;
}
</style>
