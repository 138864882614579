<template>
  <div class="outer-bounding-box">
    <div class="slideshow-wrapper">
      <button class="arrow left" @click="prevSlide">❮</button>
      <div class="slideshow-container">
        <div
          class="slideshow-window"
          :style="{
            transform: `translateX(${-currentIndex * slideWidth}px)`,
            transition: isTransitioning ? 'transform 1s ease-in-out' : 'none',
          }"
          @transitionend="handleTransitionEnd"
        >
          <div
            v-for="(image, index) in displayImages"
            :key="index"
            class="slide"
            :style="getSlideStyle(index)"
          >
            <img v-if="image" :src="image" alt="Slideshow Image" />
          </div>
        </div>
      </div>
      <button class="arrow right" @click="nextSlide">❯</button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, toRefs } from 'vue';

export default defineComponent({
  props: {
    images: {
      type: Array as () => string[], // Array of image URLs (strings)
      required: true,
    },
  },
  setup(props) {
    const { images } = toRefs(props);

    const currentIndex = ref<number>(0);  // Track current image index
    const slideWidth = 500;               // Width of each slide
    const isTransitioning = ref<boolean>(true);  // Transition state
    let slideInterval: ReturnType<typeof setInterval>; // Interval reference

    // Start the automatic slideshow on mount
    const startSlideInterval = () => {
      slideInterval = setInterval(nextSlide, 5000); // Change slides every 5 seconds
    };

    // Move to the previous slide
    const prevSlide = () => {
      isTransitioning.value = true;
      currentIndex.value = currentIndex.value > 0 ? currentIndex.value - 1 : images.value.length - 1;
    };

    // Move to the next slide or reset to first slide
    const nextSlide = () => {
      if (currentIndex.value < images.value.length - 1) {
        currentIndex.value++;
      } else {
        currentIndex.value = 0; // Reset to the first slide when reaching the end
      }
      isTransitioning.value = true;
    };

    // Handle the transition end event
    const handleTransitionEnd = () => {
      isTransitioning.value = false;
    };

    // Apply styles for the active slide (scale and opacity)
    const getSlideStyle = (index: number) => {
      return {
        transform: index === currentIndex.value ? `scale(1)` : `scale(0.8)`,
        opacity: index === currentIndex.value ? '1' : '0',
        transition: 'transform 1s ease-in-out, opacity 1s ease-in-out',
      };
    };

    // Start the slideshow interval when component is mounted
    onMounted(() => {
      startSlideInterval();
    });

    // Only include valid string URLs in the displayed images
    const displayImages = computed(() => images.value.filter(image => typeof image === 'string'));

    return {
      currentIndex,
      nextSlide,
      prevSlide,
      slideWidth,
      isTransitioning,
      handleTransitionEnd,
      displayImages,
      getSlideStyle,
    };
  },
});
</script>

<style scoped>
.outer-bounding-box {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, rgba(55, 62, 64, 0) 0%, rgba(55, 62, 64, 1) 10%, rgba(55, 62, 64, 1) 90%, rgba(55, 62, 64, 0) 100%);
  padding: 20px;
  position: relative;
  margin-left: 35px;
  margin-right: 35px;
}

.slideshow-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 600px;
}

.slideshow-container {
  position: relative;
  width: 500px;
  height: 500px;
  background-color: #2c3233;
  overflow: hidden;
}

.slideshow-window {
  display: flex;
  height: 100%;
}

.slide {
  min-width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slide img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  transition: transform 1s ease-in-out, opacity 1s ease-in-out;
}

.left,
.right {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 20px;
  cursor: pointer;
  z-index: 10;
  transition: background-color 0.3s ease;
}

.left:hover,
.right:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.left {
  margin-right: -100px;
}

.right {
  margin-left: -100px;
}
</style>
