<template>
  <nav class="navbar" :class="{ 'collapsed': !isMenuOpen }">
    <!-- Large Screen Navbar (1120px and above) -->
    <div class="navbar-container-large">
      <div class="navbar-brand-large">
        <img src="@/assets/Watermark Initials_White.png" alt="MEJ" class="initials-size navbar-logo" />
        <router-link class="navbar-link" to="/">Mark Eoghan Jones <span class="small-font">Art</span></router-link>
      </div>
      <ul class="navbar-links-large">
        <li v-for="link in links" :key="link.text">
          <router-link
            v-if="!link.external"
            class="nav-link"
            :to="link.path"
            :class="{ active: isActive(link.path) }"
            @click="handleLinkClick"
          >
            {{ link.text }}
          </router-link>
          <a
            v-else
            class="nav-link"
            :href="link.path"
            @click.prevent="openInNewWindow(link.path)"
            @click="handleLinkClick"
          >
            {{ link.text }}
          </a>
        </li>
      </ul>
    </div>

    <!-- Small Screen Navbar (below 1120px) -->
    <div class="navbar-container-small">
      <div class="navbar-header-small">
        <div class="navbar-brand-small">
          <img src="@/assets/Watermark Initials_White.png" alt="MEJ" class="initials-size navbar-logo" />
          <router-link class="navbar-link-small" to="/">Mark Eoghan Jones <span class="small-font">Art</span></router-link>
        </div>
        <div class="hamburger-menu" @click="toggleMenu" :class="{ 'open': isMenuOpen }">
          <span class="bar1"></span>
          <span class="bar2"></span>
        </div>
      </div>
      <ul :class="['navbar-links-small', { 'show': isMenuOpen }]">
        <li v-for="link in links" :key="link.text">
          <router-link
            v-if="!link.external"
            class="nav-link"
            :to="link.path"
            :class="{ active: isActive(link.path) }"
            @click="handleLinkClick"
          >
            {{ link.text }}
          </router-link>
          <a
            v-else
            class="nav-link"
            :href="link.path"
            @click.prevent="openInNewWindow(link.path)"
            @click="handleLinkClick"
          >
            {{ link.text }}
          </a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavbarComp',
  data() {
    return {
      isMenuOpen: false,
      links: [
        { text: 'Home', path: '/' },
        { text: 'Illustrations', path: '/illustrations' },
        { text: 'Sequentials', path: '/sequentials' },
        { text: 'Publications', path: '/publications' },
        { text: 'About', path: '/about' },
        { text: 'Contact', path: '/contact' },
        { text: 'Shop', path: 'https://shop.markeoghanjones.art', external: true }
      ]
    };
  },
  methods: {
    isActive(path) {
      return this.$route.path === path;
    },
    openInNewWindow(url) {
      const width = window.innerWidth;
      const height = window.innerHeight;
      window.open(url, '_blank', `noopener,noreferrer,width=${width},height=${height}`);
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    handleLinkClick() {
      this.isMenuOpen = false;
    },
  }
};
</script>

<style scoped>
/* Common Styling */
.navbar {
  width: 100%;
  background-color: #262A2B;
  color: #fff;
  padding: 0.5rem 1rem;
  transition: padding 0.3s ease;
}

/* Collapsed state only adjusts padding and height */
.navbar.collapsed .navbar-container-small {
  padding: 0.2rem 1rem; /* Reduce padding when collapsed */
  height: auto; /* Automatically adjust the height when collapsed */
}

/* Transition effects for the menu */
.navbar-links-small {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  padding: 0; /* Collapse padding when links are hidden */
  margin: 0;  /* Remove margin when links are hidden */
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out, padding 0.3s ease-in-out, margin 0.3s ease-in-out;
}

.navbar-links-small.show {
  max-height: 300px;
  opacity: 1;
  padding-top: 10px; /* Add some padding when links are shown */
  margin-top: 10px;  /* Add some margin when links are shown */
}

.small-font {
  font-size: 0.5em;
}

.initials-size {
  max-width: 50px;
  max-height: 60px;
  width: auto;
  height: auto;
}

.navbar-link {
  color: #ffffff !important;
  text-decoration: none !important;
  font-size: 1.6rem;
}

.navbar-link-small {
  color: #ffffff !important;
  text-decoration: none !important;
  font-size: 1.3rem;
}

/* Large Screen Navbar Styling (1120px and above) */
.navbar-container-large {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
}

.navbar-brand-large {
  display: flex;
  align-items: center;
  gap: 20px;
}

.navbar-links-large {
  list-style: none;
  display: flex;
  gap: 1rem;
}

.navbar-links-large .nav-link {
  color: #ffffff !important;
  text-decoration: none !important;
  position: relative;
  display: inline-block;
  border-top: 2px solid transparent;
}

.navbar-links-large .nav-link.active {
  border-bottom: 2px solid #ffffff;
}

.navbar-links-large .nav-link:hover {
  border-top: 2px solid #ffffff !important;
  color: #ffffff !important;
}

/* Small Screen Navbar Styling (below 1120px) */
.navbar-container-small {
  width: 100%;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.3rem 1rem; /* Reduce overall height */
}

.navbar-header-small {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.navbar-brand-small {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  gap: 20px;
}

.hamburger-menu {
  display: block;
  width: 24px;
  height: 24px;
  position: relative;
  cursor: pointer;
  z-index: 1000;
  margin-left: 10px;
}

.hamburger-menu span {
  display: block;
  position: absolute;
  width: 24px; /* Correct width for the bars */
  height: 2px;
  background-color: #fff;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.bar1 {
  top: 50%;
  left: 0;
  transform: translateY(-50%) rotate(0);
}

.bar2 {
  top: 50%;
  left: 0;
  transform: translateY(-50%) rotate(90deg);
}

.hamburger-menu.open .bar1 {
  transform: translateY(-50%) rotate(45deg);
}

.hamburger-menu.open .bar2 {
  transform: translateY(-50%) rotate(-45deg);
}

.navbar-links-small .nav-link {
  color: #ffffff !important;
  text-decoration: none !important;
  display: block;
  width: 100%;
  text-align: center;
  border-top: 2px solid transparent;
  font-size: 1.4rem;
}

.navbar-links-small .nav-link.active {
  border-bottom: 2px solid #ffffff;
}

.navbar-links-small .nav-link:hover {
  border-top: 2px solid #ffffff !important;
  color: #ffffff !important;
}

/* Media queries */
@media (min-width: 1120px) {
  .navbar-container-large {
    display: flex;
  }

  .navbar-container-small {
    display: none; /* Hide the small navbar on large screens */
  }
}

@media (max-width: 1120px) {
  .navbar-container-large {
    display: none; /* Hide the large navbar on smaller screens */
  }

  .navbar-container-small {
    display: flex; /* Show the small navbar on smaller screens */
  }
}


</style>
