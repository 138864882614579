<template>
    <nav class="footer-navbar">
      <div class="footer-content">
        <div class="social-links">
          <a href="https://x.com/MarkEoghanJones" target="_blank" aria-label="Twitter">
            <i class="fab fa-twitter"></i> <!-- Twitter Icon -->
          </a>
          <a href="https://www.facebook.com/profile.php?id=61561414348090" target="_blank" aria-label="Facebook">
            <i class="fab fa-facebook"></i> <!-- Facebook Icon -->
          </a>
          <a href="https://www.instagram.com/markeoghanjones/" target="_blank" aria-label="Instagram">
            <i class="fab fa-instagram"></i> <!-- Instagram Icon -->
          </a>
          <a href="mailto:mark@markeoghanjones.art" aria-label="Email">
            <i class="fas fa-envelope"></i> <!-- Email Icon -->
          </a>
        </div>
        <div class="copyright">
          © 2024 Mark Eoghan Jones Art. All rights reserved.
        </div>
      </div>
    </nav>
  </template>
  
  <script>
  export default {
    name: 'FooterNavbarComp'
  };
  </script>
  
  <style scoped>
  .footer-navbar {
    width: 100%;
    background-color: #262A2B;
    color: white;
    text-align: center;
    padding: 10px 20px;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1000;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    flex-wrap: wrap;
  }
  
  .social-links a {
    color: #fff;
    text-decoration: none;
    margin: 0 10px;
    font-size: 1.5rem;
  }
  
  .social-links a:hover {
    color: #ccc;
  }
  
  .social-links {
    display: flex;
    gap: 1rem;
  }
  
  .copyright {
    font-size: 0.6rem;
  }
  </style>
  