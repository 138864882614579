<template>
  <div class="modal-overlay" @click="close">
    <div class="modal-content" @click.stop>
      <button class="close-button" @click="close">×</button>
      <div class="image-container">
        <img :src="slides[currentIndex].image" alt="Slideshow Image" />
        <div class="caption">{{ slides[currentIndex].text || '' }}</div>
      </div>
      <button v-if="slides.length > 1" class="nav-button prev" @click="prevSlide">‹</button>
      <button v-if="slides.length > 1" class="nav-button next" @click="nextSlide">›</button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

interface Slide {
  image: string;
  text?: string;
}

export default defineComponent({
  name: 'ModalSlideshow',
  props: {
    slides: {
      type: Array as () => Slide[],
      required: true,
    },
    caption: {
      type: String,
      default: '',
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const currentIndex = ref(0);

    const close = () => {
      emit('close');
    };

    const prevSlide = () => {
      currentIndex.value =
        (currentIndex.value - 1 + props.slides.length) % props.slides.length;
    };

    const nextSlide = () => {
      currentIndex.value =
        (currentIndex.value + 1) % props.slides.length;
    };

    return {
      currentIndex,
      close,
      prevSlide,
      nextSlide,
    };
  },
});
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border: none;
  padding: 5px 10px;
  font-size: 24px;
  cursor: pointer;
  z-index: 1001;
}

.close-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.image-container {
  text-align: center;
}

img {
  max-width: 100%;
  max-height: 80vh;
}

.caption {
  margin-top: 10px;
  font-size: 16px;
  color: #262A2B;
}

.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border: none;
  padding: 10px;
  font-size: 24px;
  cursor: pointer;
  z-index: 1001;
}

.nav-button.prev {
  left: 10px;
}

.nav-button.next {
  right: 10px;
}

.nav-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
</style>
