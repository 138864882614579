<template>
  <div>
    <span style="display: inline-block; margin-bottom: 10px;">
      Welcome to the Online Art Gallery of Mark Eoghan Jones!
    </span>

    <!-- Only render the HomeSlideshow component if images are available -->
    <HomeSlideshow v-if="images.length > 0" :images="images" />
    <div v-else>Loading...</div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import HomeSlideshow from '@/components/HomeSlideshow.vue';
import API_URL from '@/config';

export default defineComponent({
  name: 'HomeView',
  components: {
    HomeSlideshow,
  },
  setup() {
    const images = ref<string[]>([]); // Explicit typing as an array of strings

    const fetchAllImages = async () => {
      try {
        const response = await fetch(`${API_URL}/home-images`);
        if (!response.ok) {
          throw new Error(`Failed to fetch images: ${response.statusText}`);
        }

        const data = await response.json();
        // Ensure each image is a string and push to images array
        data.forEach((folder: any) => {
          folder.jsonData.slides.forEach((slide: any) => {
            images.value.push(slide.imageUrl as string);  // Add images to array
          });
        });
      } catch (error) {
        console.error('Error fetching all images:', error);
      }
    };

    onMounted(() => {
      fetchAllImages();
    });

    return {
      images,
    };
  },
});
</script>

<style scoped>
* {
  color: #fff;
  font-size: 13px;
}
</style>
