import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-4240f91d"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "slideshow-container",
  ref: "container"
}
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = {
  key: 0,
  class: "slide-number"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "slideshow-track",
      ref: "slideshowTrack",
      style: _normalizeStyle(_ctx.singleImage ? _ctx.singleImageStyle : _ctx.trackStyle),
      onTransitionend: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleTransitionEnd && _ctx.handleTransitionEnd(...args)))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displaySlides, (slide, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "slide"
        }, [
          _createElementVNode("img", {
            src: slide,
            alt: 'Slideshow Image ' + (index + 1)
          }, null, 8, _hoisted_2),
          (_ctx.displayNumber && !_ctx.singleImage)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.getDisplayNumber(index)), 1))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ], 36)
  ], 512))
}