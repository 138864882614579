<template>
  <div>
    <h1>About</h1>
    
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AboutView'
})
</script>

<style scoped>
* {
  color: white;
}
</style>